/**
 * @license
 * Copyright 2021 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */

import '@tensorflow/tfjs-backend-webgl';
import '@tensorflow/tfjs-backend-webgpu';

import * as mpPose from '@mediapipe/pose';
import * as tfjsWasm from '@tensorflow/tfjs-backend-wasm';
import Plotly from 'plotly.js-dist';

tfjsWasm.setWasmPaths(
    `https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@${tfjsWasm.version_wasm}/dist/`);

import * as posedetection from '@tensorflow-models/pose-detection';

import {Camera} from './camera';
import {RendererWebGPU} from './renderer_webgpu';
import {RendererCanvas2d} from './renderer_canvas2d';
import {setupDatGui} from './option_panel';
import {STATE} from './params';
import {setBackendAndEnvFlags} from './util';

let detector; let camera;
let lastUpdateDttm = 0;
let rafId;
let renderer = null;
let useGpuRenderer = false;
let referenceAverageShoulderPosition;
let referenceEyeDistance;
let lastNotificationTime = 0;

// Configs
const NOTIFICATION_FREQUENCY = 300000; // 5 minutes

// check that the user supports and allows notifications
if (!('Notification' in window)) {
  console.log('This browser does not support desktop notification');
} else if (Notification.permission !== 'denied' || Notification.permission === 'default') {
  // ask for user permission to send notifications
  Notification.requestPermission();
}


async function createDetector() {
  switch (STATE.model) {
    case posedetection.SupportedModels.PoseNet:
      return posedetection.createDetector(STATE.model, {
        quantBytes: 4,
        architecture: 'MobileNetV1',
        outputStride: 16,
        inputResolution: {width: 500, height: 500},
        multiplier: 0.75,
      });
    case posedetection.SupportedModels.BlazePose:
      const runtime = STATE.backend.split('-')[0];
      if (runtime === 'mediapipe') {
        return posedetection.createDetector(STATE.model, {
          runtime,
          modelType: STATE.modelConfig.type,
          solutionPath:
            `https://cdn.jsdelivr.net/npm/@mediapipe/pose@${mpPose.VERSION}`,
        });
      } else if (runtime === 'tfjs') {
        return posedetection.createDetector(
            STATE.model, {runtime, modelType: STATE.modelConfig.type});
      }
    case posedetection.SupportedModels.MoveNet:
      let modelType;
      if (STATE.modelConfig.type == 'lightning') {
        modelType = posedetection.movenet.modelType.SINGLEPOSE_LIGHTNING;
      } else if (STATE.modelConfig.type == 'thunder') {
        modelType = posedetection.movenet.modelType.SINGLEPOSE_THUNDER;
      } else if (STATE.modelConfig.type == 'multipose') {
        modelType = posedetection.movenet.modelType.MULTIPOSE_LIGHTNING;
      }
      const modelConfig = {modelType};

      if (STATE.modelConfig.customModel !== '') {
        modelConfig.modelUrl = STATE.modelConfig.customModel;
      }
      if (STATE.modelConfig.type === 'multipose') {
        modelConfig.enableTracking = STATE.modelConfig.enableTracking;
      }
      return posedetection.createDetector(STATE.model, modelConfig);
  }
}

async function checkGuiUpdate() {
  if (STATE.isTargetFPSChanged || STATE.isSizeOptionChanged) {
    camera = await Camera.setupCamera(STATE.camera);
    STATE.isTargetFPSChanged = false;
    STATE.isSizeOptionChanged = false;
  }

  if (STATE.isModelChanged || STATE.isFlagChanged || STATE.isBackendChanged) {
    STATE.isModelChanged = true;

    window.cancelAnimationFrame(rafId);

    if (detector != null) {
      detector.dispose();
    }

    if (STATE.isFlagChanged || STATE.isBackendChanged) {
      await setBackendAndEnvFlags(STATE.flags, STATE.backend);
    }

    try {
      detector = await createDetector(STATE.model);
    } catch (error) {
      detector = null;
      alert(error);
    }

    STATE.isFlagChanged = false;
    STATE.isBackendChanged = false;
    STATE.isModelChanged = false;
  }
}

let lastPose = null;
const averageShoulderPositions = [];
const eyeDistances = [];
const eyePositions = [];

function drawPlotlyPlot(items, referencePosition, title, divName, itemKey = 'position') {
  const data = [
    {
      x: items.map((item) => item.time),
      y: items.map((item) => item[itemKey]),
      type: 'scatter',
    },
  ];
  Plotly.newPlot(divName, data, {title: title});
  // if referencePosition is defined, make it a second line on the items plot
  if (referencePosition) {
    const referenceData = [
      {
        x: items.map((item) => item.time),
        y: Array(items.length).fill(referencePosition),
        type: 'scatter',
        name: 'reference',
      },
    ];
    Plotly.addTraces(divName, referenceData);
  }
}

function checkAverageAgainstReference(values, reference, shouldAverageBeHigher, itemKey, checkName) {
  const lastValues = values.slice(-12);
  const lastValuesSum = lastValues.reduce((a, b) => a + b[itemKey], 0);
  const lastValuesAverage = lastValuesSum / lastValues.length;
  console.log('lastValuesAverage', lastValuesAverage);

  if (reference &&
      ((shouldAverageBeHigher && lastValuesAverage < reference) ||
       (!shouldAverageBeHigher && lastValuesAverage > reference))) {
    // send a notification if the user was not notified in the last 5 minutes
    console.log('Condition is not met');
    if (Date.now() - lastNotificationTime > NOTIFICATION_FREQUENCY) {
      const notification = new Notification(`Your ${checkName} is ${shouldAverageBeHigher ? 'lower' : 'higher'} than reference!`);

      // Close the notification after 10 seconds
      setTimeout(function() {
        notification.close();
      }, 10000);
      // save last notification time
      lastNotificationTime = Date.now();
    }
  }
}

async function renderResult() {
  if (camera.video.readyState < 2) {
    await new Promise((resolve) => {
      camera.video.onloadeddata = () => {
        resolve(video);
      };
    });
  }

  let poses = null;
  let canvasInfo = null;

  // Detector can be null if initialization failed (for example when loading
  // from a URL that does not exist).
  if (detector != null) {
    // FPS only counts the time it takes to finish estimatePoses.
    // beginEstimatePosesStats();

    if (useGpuRenderer && STATE.model !== 'PoseNet') {
      throw new Error('Only PoseNet supports GPU renderer!');
    }
    // Detectors can throw errors, for example when using custom URLs that
    // contain a model that doesn't provide the expected output.
    try {
      if (useGpuRenderer) {
        const [posesTemp, canvasInfoTemp] = await detector.estimatePosesGPU(
            camera.video,
            {maxPoses: STATE.modelConfig.maxPoses, flipHorizontal: false},
            true);
        poses = posesTemp;
        canvasInfo = canvasInfoTemp;
      } else {
        // detect pose every 5 secods and keep the last pose
        if (Date.now() - lastUpdateDttm > 5000) {
          poses = await detector.estimatePoses(
              camera.video,
              {maxPoses: STATE.modelConfig.maxPoses, flipHorizontal: false});
          lastUpdateDttm = Date.now();


          // convert lastUpdateDttm to string time
          const lastUpdateDttmDate = new Date(lastUpdateDttm);
          const time = lastUpdateDttmDate.getHours() + ':' + lastUpdateDttmDate.getMinutes() + ':' + lastUpdateDttmDate.getSeconds();

          // log average sholder position to the console
          console.log(poses);
          // set as none if no shoulder detected
          let averageShoulderPosition = 'none';
          if (poses[0]?.keypoints[6] && poses[0]?.keypoints[5]) {
            averageShoulderPosition = camera.video.height -(poses[0]?.keypoints[6].y + poses[0]?.keypoints[5].y) / 2;
            averageShoulderPositions.push({time: time, position: averageShoulderPosition});
          }

          const leftEye = poses[0]?.keypoints[1];
          const rightEye = poses[0]?.keypoints[2];
          // set as none if no eyes detected
          let eyeDistance = 'none';
          let eyePosition = 'none';
          if (leftEye && rightEye) {
            eyeDistance = Math.sqrt(Math.pow(leftEye.x - rightEye.x, 2) + Math.pow(leftEye.y - rightEye.y, 2));
            eyeDistances.push({time: time, distance: eyeDistance});

            eyePosition = camera.video.height -(leftEye.y + rightEye.y) / 2;
            eyePositions.push({time: time, position: eyePosition});
          }


          // send an api post request to save averageShoulderPositions in the db
          // fields of request are user_id, dttm, average_shoulder_position
          // const response = await fetch('http://127.0.0.1:8000/postures/', {
          //   method: 'POST',
          //   headers: {
          //     'accept': 'application/json',
          //     'Content-Type': 'application/json'
          //   },
          //   body: JSON.stringify({
          //     'user_id': 1,
          //     'dttm': lastUpdateDttmDate,
          //     'raw_keypoints': JSON.stringify(poses[0]?.keypoints),
          //     'average_shoulder_position': averageShoulderPosition,
          //     'eye_distance': eyeDistance
          //   })
          // });
          // const responseData = await response.json();
          // console.log(responseData);

          // draw a scatter plot of average shoulder position
          drawPlotlyPlot(averageShoulderPositions, referenceAverageShoulderPosition, 'Average Shoulder position', 'shoulder_graph', 'position');

          // draw a scatter plot of distance between left and right eye
          drawPlotlyPlot(eyeDistances, referenceEyeDistance, 'Distance between eyes', 'eye_distance_graph', 'distance');

          // send user a notification if their shoulder position is lower than reference for at least 1 minute
          checkAverageAgainstReference(averageShoulderPositions, referenceAverageShoulderPosition, true, 'position', 'Shoulder height');

          checkAverageAgainstReference(eyeDistances, referenceEyeDistance, false, 'distance', 'Distance between eyes');

          // save last pose
          lastPose = poses;
          console.log('last pose after 5s', lastPose);
        } else {
          poses = lastPose;
        }
      }
    } catch (error) {
      detector.dispose();
      detector = null;
      alert(error);
    }

    // endEstimatePosesStats();
  }
  const rendererParams = useGpuRenderer ?
    [camera.video, poses, canvasInfo, STATE.modelConfig.scoreThreshold] :
    [camera.video, poses, STATE.isModelChanged];
  renderer.draw(rendererParams);
}

async function renderPrediction() {
  await checkGuiUpdate();

  if (!STATE.isModelChanged) {
    await renderResult();
  }

  rafId = requestAnimationFrame(renderPrediction);
};

document.getElementById('savePositionButton').addEventListener('click', function() {
  // get average shoulder position as last element from averageShoulderPositions array
  const lastAverageShoulderPosition = averageShoulderPositions[averageShoulderPositions.length - 1].position;
  const lastEyePosition = eyePositions[eyePositions.length - 1].position;
  const significantPositionRatio = 0.1; // 10% of the distance between eye position and shoulder position considered significant

  const heightTreshold = (lastEyePosition - lastAverageShoulderPosition) * significantPositionRatio;

  referenceAverageShoulderPosition = lastAverageShoulderPosition - heightTreshold;
  if (referenceAverageShoulderPosition < 0) {
    referenceAverageShoulderPosition = 0;
  }

  // display reference average shoulder position on the page
  document.getElementById('referenceAverageShoulderPosition').innerHTML = referenceAverageShoulderPosition;

  const lastEyeDistance = eyeDistances[eyeDistances.length - 1].distance;
  const significantEyeDistanceRatio = 1.2;

  referenceEyeDistance = lastEyeDistance * significantEyeDistanceRatio;
  document.getElementById('referenceEyeDistance').innerHTML = referenceEyeDistance;
});


document.getElementById('testNotificationButton').addEventListener('click', function() {
  const notification = new Notification('Test notification!');

  // Close the notification after 10 seconds
  setTimeout(function() {
    notification.close();
  }, 10000);
});

document.getElementById('toggleVideoButton').addEventListener('click', function() {
  const container = document.querySelector('.container');
  if (container.style.visibility !== 'hidden') {
    container.style.visibility = 'hidden';
    container.style.opacity = '0';
  } else {
    container.style.visibility = 'visible';
    container.style.opacity = '1';
  }
});

async function app() {
  // Gui content will change depending on which model is in the query string.
  const urlParams = new URLSearchParams(window.location.search);
  if (!urlParams.has('model')) {
    urlParams.set('model', 'movenet');
    console.log('Cannot find model in the query string, using movenet as default model');
  }
  await setupDatGui(urlParams);

  // stats = setupStats();
  const isWebGPU = STATE.backend === 'tfjs-webgpu';
  const importVideo = (urlParams.get('importVideo') === 'true') && isWebGPU;

  camera = await Camera.setup(STATE.camera);

  await setBackendAndEnvFlags(STATE.flags, STATE.backend);

  detector = await createDetector();
  const canvas = document.getElementById('output');
  canvas.width = camera.video.width;
  canvas.height = camera.video.height;
  useGpuRenderer = (urlParams.get('gpuRenderer') === 'true') && isWebGPU;
  if (useGpuRenderer) {
    renderer = new RendererWebGPU(canvas, importVideo);
  } else {
    renderer = new RendererCanvas2d(canvas);
  }

  renderPrediction();
};

app();

if (useGpuRenderer) {
  renderer.dispose();
}
